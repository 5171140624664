import React from "react";
import Banner from "../Banner/Banner";
import Banner2 from "../Banner/Banner2";
import VisionBanner from "../Banner/VisionBanner";
import DemoVedios from "../Banner/DemoVedios";
import Testimonial from "../Banner/Testimonial";
import Founder from "../Banner/Founder";

const Home = () => {
  return (
    <>
      <Banner />
      <Banner2 />
      <VisionBanner />
      <DemoVedios />
      <Testimonial />
      {/* <Founder /> */}
    </>
  );
};

export default Home;
