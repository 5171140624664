import React, { useContext } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";

const useApiAxios = () => {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem("bearer");
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  // You can also set headers for specific request methods
  axios.defaults.headers.get['Accept'] = 'application/json';
  const {
    userData,
    setUserData,
    isAuthorized,
    setIsAuthorized,
    setIsLoading,
    openInfoSnackbar,
    openErrorSnackbar,
    updateCount, setUpdateCount
  } = useContext(Context);
  const ENDPOINT = process.env.REACT_APP_ENDPOINT;

  const CONTACT = "/create/Contact"
  const SIGNUP = "/signup/subAdmin"
  const CHECK_EMAIL = "/check/email"

  const navigate = useNavigate();

  const contactApi = async ({ name, organization, phone, email, about, type }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CONTACT}`, { name, organization, phone, email, about, type });
      navigate("/");
      setIsLoading(false);
      openInfoSnackbar(res.data.message)
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const singupApi = async ({ name, organization, phone, email, about }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${SIGNUP}`, { name, organization, phone, email, about });
      navigate("/");
      setIsLoading(false);
      openInfoSnackbar(res.data.message)
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const checkEmailApi = async ({ email }) => {
    try {
      const res = await axios.post(`${ENDPOINT}${CHECK_EMAIL}`, { email });
      return { error: false, data: res.data.message }
    } catch (error) {
   
      return { error: true, data: error.response.data.message }
    } finally {
      setIsLoading(false);
    }
  };
  
  return {
    contactApi,
    singupApi,
    checkEmailApi
  };
};

export default useApiAxios;
