import React from "react";
import useApiAxios from "../../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";

import  twitter from '../../twitter.svg';

const Footer = () => {
  const { contactApi } = useApiAxios();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      about: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Name must contain only alphabetic characters")
        .matches(/^\S.*$/, "Name cannot start with a space")
        .required("Name is required")
        .min(2, " Name should be at least 2 characters"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(/^\S.*$/, "Email cannot start with a space")
        .required("Email is required"),
      about: Yup.string()
        .matches(/^[a-zA-Z0-9\s\S]*$/, "About should only contain alphabets, numbers, and special characters")
        .matches(/^\S.*$/, "Query cannot start with a space")
        .max(256, "Query should not exceed 256 characters")
        .required("Query is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      contactApi({ ...values, type: "questions" })
        .then(() => {
          resetForm(); // Reset the form after successful response
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  const handleBeforeInput = (event) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(event.data)) {
      event.preventDefault();
    }
  };
  return (
    <footer id="target" className="ftco-footer ftco-section">
      <div className="container">
        <div className="row justify-content-between mb-5">
          <div className="col-md-5">
            <div className="ftco-footer-widget mb-4">
              <img
                src="/assets/images/logo-white.svg"
                width={250}
                className="pb-3"
              />
              <p>
                Eventease360 software based on what you, our market managers,
                ask for and the feedback you give us each season, continually
                delivering the tools that empower you to process applications,
                run your market, keep vendor data secure and gain all the
                valuable insights that a data-driven platform can provide.
              </p>
            </div>
            <div className="ftco-footer-widget mb-4">
              <div className="block-23 mb-3">
                <ul className="row ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li className="">
                    <a href="#">
                      <img src= {twitter} style={{height: '25px', margin: "13px"}}></img>
                    </a>
                  </li>
                  <li className="">
                    <a href="#">
                      <span className="icon-facebook" />
                    </a>
                  </li>
                  <li className="">
                    <a href="#">
                      <span className="icon-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <h2 className="ftco-heading-2 text-white">Have a Questions?</h2>
            <form onSubmit={formik.handleSubmit} className="contact-form">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Your Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onBeforeInput={handleBeforeInput}

                />
                {formik.touched.name && formik.errors.name && (
                  <small className="text-danger">{formik.errors.name}</small>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Your Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <small className="text-danger">{formik.errors.email}</small>
                )}
              </div>
              <div className="form-group">
                <textarea
                  id="about"
                  name="about"
                  className="form-control"
                  placeholder="Tell us about your market or event"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.about}
                  maxLength={256}
                />
                <p className="text-end mb-0">
                  <small className="text-end">
                    {formik.values.about.length}/256
                  </small>
                </p>
                {formik.touched.about && formik.errors.about && (
                  <small className="text-danger">{formik.errors.about}</small>
                )}
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  defaultValue="Send Message"
                  className="btn btn-primary py-3 px-5"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              Copyright &copy;
              {new Date().getFullYear()} All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
