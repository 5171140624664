import React from "react";
import { Link } from "react-router-dom";

const Banner2 = () => {
  return (
    <>
      <section className="ftco-section ftco-services">
        <div className="container">
          <div className="justify-content-center text-center pb-5">
            <p>
              <img src="/assets/images/logo-icon.png" className="logo_icon" />
            </p>
            <h5 className="mb-5">
              Step into a world where event management, collaboration, and
              market navigation become effortless with Ease 360 Market Software.
              Seamlessly integrating intuitive features, our platform empowers
              you to orchestrate unforgettable events, streamline collaborative
              efforts, and navigate markets with unparalleled ease. Experience
              the transformation as complexities dissolve into simplicity.
              Elevate your event planning, streamline your collaborations, and
              conquer market challenges effortlessly. Embrace efficiency,
              embrace success—and unlock the gateway to effortless management
              today.
            </h5>
            <p>
              <Link to={"/free-trial"} className="btn px-4 py-3" style={{backgroundColor: '#ff6f61', color: 'white'}}>
                Buy Now
              </Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner2;
