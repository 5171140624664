import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonial = () => {
  return (
    <section className="ftco-section testimony-section" id="testimony-section">
      <div className="container">
        <div className="row justify-content-center pb-3">
          <div className="col-md-7 text-center heading-section heading-section-white ">
            <h2 className="mb-4">Happy Clients</h2>
          </div>
        </div>
        <div className="row  justify-content-center">
          <div className="col-md-12">
            <OwlCarousel
              responsive={{
                0: {
                  items: 1,
                },
                576: {
                  items: 2,
                },
                768: {
                  items: 2,
                },
                992: {
                  items: 3,
                },
                1200: {
                  items: 3,
                },
              }}
              margin={25}
              className="carousel-project owl-carousel"
            >
              <div className="item">
                <div className="testimony-wrap text-center py-4 pb-5">
                  <div
                    className="user-img"
                    style={{
                      backgroundImage: "url(/assets/images/testimonial3.png)",
                    }}
                  >
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left" />
                    </span>
                  </div>
                  <div className=" px-4 pb-5">
                    <p className="mb-4">
                      Event ease 360 elevated our trade show coordination! From
                      exhibitor management to floor planning , its the ultimate
                      tool for event owners.
                    </p>
                    <p className="name">Charlotte</p>
                  
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap text-center py-4 pb-5">
                  <div
                    className="user-img"
                    style={{
                      backgroundImage: "url(/assets/images/testimonials2.png)",
                    }}
                  >
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left" />
                    </span>
                  </div>
                  <div className="px-4 pb-5">
                    <p className="mb-4">
                      Thanks to event ease 360 our music festival was so easier
                      to manage from seamless registration for our vendors to
                      tracking all aspects of the backend of running an event .
                      We could be more happier
                    </p>
                    <p className="name">William</p>
                   
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap text-center py-4 pb-5">
                  <div
                    className="user-img"
                    style={{
                      backgroundImage: "url(/assets/images/testimonials1.png)",
                    }}
                  >
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left" />
                    </span>
                  </div>
                  <div className="px-4 pb-5">
                    <p className="mb-4">
                      Event ease 360 elevated our market from a small once a
                      week market to now having 3 locations and triple the
                      amount of vendors without this software we would of never
                      been able to scale like this.
                    </p>
                    <p className="name">Willow</p>
                   
                  </div>
                </div>
              </div>
              {/* <div className="item">
                <div className="testimony-wrap text-center py-4 pb-5">
                  <div
                    className="user-img"
                    style={{
                      backgroundImage: "url(/assets/images/person_4.jpg)",
                    }}
                  >
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left" />
                    </span>
                  </div>
                  <div className="text px-4 pb-5">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <p className="name">Charlie</p>
                    <span className="position">Businessman</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap text-center py-4 pb-5">
                  <div
                    className="user-img"
                    style={{
                      backgroundImage: "url(/assets/images/person_3.jpg)",
                    }}
                  >
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left" />
                    </span>
                  </div>
                  <div className="text px-4 pb-5">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <p className="name">William</p>
                    <span className="position">Businessman</span>
                  </div>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
