import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const DemoVedios = () => {
  return (
    <section id="projects-section">
      <div className="container px-md-5 py-5 mt-5">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 heading-section text-center ">
            <h2 className="mb-4">Demo videos and tutorials</h2>
            <p>Browse latest videos and tutorials</p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-12 testimonial">
            <OwlCarousel
              autoplay={true}
              autoHeight={true}
              center={true}
              loop={true}
              items={1}
              margin={30}
              stagePadding={0}
              nav={false}
              dots={true}
              navText={[
                '<span class="ion-ios-arrow-back">',
                '<span class="ion-ios-arrow-forward">',
              ]}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 2,
                },
              }}
              className="carousel-project owl-carousel"
            >
              <div className="item">
                <div className="project">
                  <div className="img">
                    <div className="play">
                      <span className="ion-ios-play" />
                    </div>
                    <img
                      src="/assets/images/video1.jpg"
                      className="img-fluid"
                      alt="Colorlib Template"
                    />
                    <div className="text px-4">
                      <h3>
                        <a href="#">Dashboard</a>
                      </h3>
                      <span>Adminpanel</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="project">
                  <div className="img">
                    <div className="play">
                      <span className="ion-ios-play" />
                    </div>
                    <img
                      src="/assets/images/video2.jpg"
                      className="img-fluid"
                      alt="Colorlib Template"
                    />
                    <div className="text px-4">
                      <h3>
                        <a href="#">Manage User Details</a>
                      </h3>
                      <span>Adminpanel</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="project">
                  <div className="img">
                    <div className="play">
                      <span className="ion-ios-play" />
                    </div>
                    <img
                      src="/assets/images/video3.jpg"
                      className="img-fluid"
                      alt="Colorlib Template"
                    />
                    <div className="text px-4">
                      <h3>
                        <a href="#">Manage User Details</a>
                      </h3>
                      <span>Adminpanel</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="project">
                  <div className="img">
                    <div className="play">
                      <span className="ion-ios-play" />
                    </div>
                    <img
                      src="/assets/images/video4.jpg"
                      className="img-fluid"
                      alt="Colorlib Template"
                    />
                    <div className="text px-4">
                      <h3>
                        <a href="#">Manage User Details</a>
                      </h3>
                      <span>Adminpanel</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="project">
                  <div className="img">
                    <div className="play">
                      <span className="ion-ios-play" />
                    </div>
                    <img
                      src="/assets/images/video3.jpg"
                      className="img-fluid"
                      alt="Colorlib Template"
                    />
                    <div className="text px-4">
                      <h3>
                        <a href="#">Manage User Details</a>
                      </h3>
                      <span>Adminpanel</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="project">
                  <div className="img">
                    <div className="play">
                      <span className="ion-ios-play" />
                    </div>
                    <img
                      src="/assets/images/video6.jpg"
                      className="img-fluid"
                      alt="Colorlib Template"
                    />
                    <div className="text px-4">
                      <h3>
                        <a href="#">Manage User Details</a>
                      </h3>
                      <span>Adminpanel</span>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoVedios;
